.Ap {
  text-align: center;
  background-color: #eaeef3;
  width: 100%;
  color: #000;
  font-family: "Poppins-Regular", Helvetica;
}

button {
  background-color: #cde5ff;
  border: 1px;
  height: 40px;
  width: 99%;
}

button:hover {
  background-color: #9bc3ed;
}
.tools {
  position: fixed;
  padding: 2%;
  width: 15%;
  height: 20%;
}

.bottom-container {
  height: 300px;
  text-align: left;
  border: 3px solid #5f5959;
  position: fixed;
  bottom: 0;
}
