/* VerticalNavBar.css */
.vertical-nav-bar {
    width: 250px; /* Adjust the width as needed */
    color: #161515;
    padding: 20px;
    margin-left: 10px;
  }
  
  .vertical-nav-bar a {
    color: #1a1919;
    text-decoration: none;
    display: block;
    padding: 10px 0;
    color:inherit ;
  }
  
  .vertical-nav-bar a:hover {
    background-color: #EEF3FB;
    color: #101010;
    color:inherit;
    cursor: pointer;
  }
  