.Report {
  background-color: rgb(255, 255, 255);
  width: 100%;
  margin: 2% 0 0 0;
}

#horzontalLine {
  height: 1%;
  width: 100%;
  border-color: #cccccc;
}

.comments-section {
  color: black;
  width: 90%;
  align-items: center;
  margin: 5%;
  gap: 20%;
}
.comment {
  padding: auto;
  float: inline-end;
}
/* table {
  
  width: 100%;  
}
table tr:hover {
  background-color: #ffffff; 
}
.td {
  background-color: #ffffff !important;
  text-align: left;
} */
label{
padding: 20px;
}