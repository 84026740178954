table th td{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left ;
  background-color: #dee2e6;
  color: rgb(0, 0, 0);
}

table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  width: 100%;
}

th,
td {
  padding: 1px;
}

tr {
  background-color: #fff;
}

td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}
td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}
table tr:hover {
  background-color: #c7e0ea;
  
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
}

.dropdown-button {
  background-color: rgba(255, 255, 255, 0.082);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #DFDADA;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.left {
  right: 5%;
  transform: translateX(-10%);
}
