.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Restrict to 2 columns */
  gap: 20px; /* Space between items */
  padding: 10px; /* Space around the grid */
  background-color: #ffffff; /* Optional background */
  width: 100%; /* Ensures the grid spans full width */
  box-sizing: border-box; /* Includes padding in width calculations */
}

.grid-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden; /* Ensures content stays within the container */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for better visuals */
}

.grid-item img {
  width: 100%; /* Ensures the image fills the container's width */
  height: auto; /* Maintains the image's aspect ratio */
  object-fit: contain; /* Scales the image to fit without cropping */
  border-radius: 10px; /* Matches container's border */
}